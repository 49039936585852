import 'popper.js'
import 'bootstrap'
import $ from 'jquery'
import flatpickr from "flatpickr"
import {German} from "flatpickr/dist/l10n/de"
import "chosen-js/chosen.jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "chartkick/chart.js"

Rails.start()
Turbolinks.start()

$(document).ready(function () {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
    callFlatpickrJS();
    callChosenJS();
})

const callFlatpickrJS = function () {
    flatpickr.localize(flatpickr.l10ns.de)

    flatpickr(".datepicker", {
        altInput: true,
        allowInput: true,
        altFormat: "d.m.Y",
        dateFormat: "Y.m.d"
    })
    flatpickr(".datetimepicker", {
        time_24hr: true,
        enableTime: true,
        altInput: true,
        allowInput: true,
        altFormat: "d.m.Y, H:i",
        dateFormat: "Y.m.d H:i"
    });


};

const callChosenJS = function () {
    $(".chosen-select").each(function () {
        if ($(this).css('display') === 'none') return
        var select, chosen;
        // Cache the select element as we'll be using it a few times
        select = $(this);
        // Init the chosen plugin
        select.chosen({
            allow_single_deselect: true,
            placeholder_text_single: select.data('empty') ? select.data('empty') : ' ',
            placeholder_text_multiple: select.data('empty') ? select.data('empty') : 'keine',
            width: '100%',
            search_contains: true,
            no_results_text: select.closest('form').hasClass('assoc_creatable') ? 'Neue Kategorie mit' : 'Keine Resultate'
        });
        // Get the chosen object
        chosen = select.data('chosen');
        if (select.closest('form').hasClass('assoc_creatable')) {
            // Bind the keyup event to the search box input
            var func = function (e) {
                if (e.which == 13 && chosen.dropdown.find('li.no-results').length > 0) {
                    if (confirm(I18n.t('js.chosen.new_category', {catgory: this.value}))) {
                        var data = {};
                        data[select.closest('form').data('creatable-object')] = {company: this.value};
                        data['assoc'] = true;
                        $.ajax({
                            method: 'POST',
                            url: select.closest('form').data('creatable-url'),
                            data: data
                        })
                            .done(function (data) {
                                if (data.status == "ok") {
                                    a
                                    alertify.success(data.message);
                                    var option = $("<option value='" + data[select.closest('form').data('creatable-object')].id + "'>").text(data[select.closest('form').data('creatable-object')].title);
                                    // Add the new option to every select of same name
                                    $("select.chosen-select[name='" + select.attr('name') + "']").each(function () {
                                        console.log($(this).attr('id'));
                                        $(this).append(option.clone());
                                        $(this).trigger("chosen:updated");
                                    });
                                    select.prepend(option);
                                    // Automatically select it
                                    select.find(option).prop('selected', true);
                                    // Trigger the update
                                    select.trigger("chosen:updated");
                                    select.trigger("change");
                                } else {
                                    alertify.error(data.message);
                                    select[0].reset();
                                    select.find('select').trigger('chosen:updated');
                                }
                            });
                    }
                }
            };
            chosen.dropdown.find('input').unbind('keydown.assoc_creatable');
            chosen.dropdown.find('input').on('keydown.assoc_creatable', func);
        }

    });
};

//// disable mousewheel on a input number field when in focus
$('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('wheel.disableScroll', function (e) {
        e.preventDefault()
    })
})
$('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('wheel.disableScroll')
})
